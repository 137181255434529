/* General container styling */
.chart-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 13px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
  height: 100%;
  max-width: 100%;
  overflow: hidden;
}

/* Styling for the year filter buttons */
.year-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-bottom: 15px;
  justify-content: center;
}

.year-button {
  background-color: #3498db;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
  min-width: 80px;
}

.year-button:hover {
  background-color: #2980b9;
}

.year-button.selected {
  background-color: #1abc9c;
}

/* Chart Wrapper */
.chart-wrapper {
  width: 100%;
  max-width: 800px;
  margin-bottom: 10px;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Specific styling for Pie Chart */
.pie {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 320px; /* Limit max width of pie chart */
  margin: 0 auto;
}

.pie canvas {
  width: 100% !important; /* Ensure pie chart scales with container */
  height: auto !important;
}

/* Responsive styling for charts */
.chart-wrapper .chartjs-render-monitor {
  width: 100% !important;
  height: auto !important;
}

/* Responsive design for medium screens */
@media (max-width: 768px) {
  .year-buttons {
    flex-direction: column;
    align-items: center;
  }

  .year-button {
    width: 90%;
    text-align: center;
    padding: 8px 14px;
  }

  .chart-wrapper {
    max-width: 95%;
  }

  .pie {
    max-width: 250px; /* Reduce pie chart size on medium screens */
  }
}

/* Responsive design for small screens */
@media (max-width: 480px) {
  .year-button {
    font-size: 12px;
    padding: 6px 10px;
    width: 100%;
    margin: 3px 0;
  }

  .chart-container {
    padding: 10px;
  }

  .chart-wrapper {
    max-width: 100%;
    padding: 5px;
  }

  .pie {
    max-width: 200px; /* Further reduce pie chart size on small screens */
  }
}

/* Responsive design for extra small screens */
@media (max-width: 360px) {
  .year-button {
    font-size: 11px;
    padding: 4px 8px;
  }

  .chart-container {
    padding: 5px;
    margin-bottom: 10px;
  }

  .chart-wrapper {
    padding: 3px;
  }

  .pie {
    max-width: 150px; /* Minimize pie chart on very small screens */
  }
}
