@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');

:root{
  scroll-behavior: smooth;
  --primary:#4154f1;
  --second:#717ff5;
  --bgColor:#f6f9ff;
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body{
  font-family:'Open Sans', sans-serif;
  color:#444444;
  background: var(--bgColor);
}

a{
  color: var(--primary);
  text-decoration: none;
  cursor: pointer;
}

a:hover{
  color: var(--second);
  text-decoration: none;
}

h1,h2,h3,h4,h5,h6{
  font-family: 'Nunito', sans-serif;
}

/* Customizing the Dropdown Menus */
.dropdown-menu {
  border-radius: 8px; /* Increased border radius for smoother edges */
  padding: 10px 0;
  -webkit-animation-name: dropdown-animate; 
  animation-name: dropdown-animate;
  -webkit-animation-duration: 0.2s;
  animation-duration: 0.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  border: 0;
  box-shadow: 0 5px 30px rgba(82, 63, 105, 0.2);
}

.dropdown-menu .dropdown-header, 
.dropdown-menu .dropdown-footer {
  text-align: center;
  font-size: 15px;
  padding: 10px 25px;
}

.dropdown-menu .dropdown-footer a {
  color: #444444;
  text-decoration: underline;
}

.dropdown-menu .dropdown-footer a:hover { 
  text-decoration: none;
}

.dropdown-menu .dropdown-divider {
  color: #a5c5fe;
  margin: 0;
}

.dropdown-menu .dropdown-item {
  font-size: 14px;
  padding: 10px 15px;
  transition: 0.3s;
  color: #000;
}

.dropdown-menu .dropdown-item i {
  margin-right: 10px !important; 
  font-size: 18px !important;
  line-height: 0 !important;
  color: #000;
}

.dropdown-menu .dropdown-item:hover {
  background-color: #f6f9ff;
}

@media (min-width: 768px) { 
  .dropdown-menu-arrow::before {
    content: '';
    width:13px;
    height:13px;
    background: #fff;
    position: absolute;
    top: -7px;
    right: 20px;
    transform: rotate(45deg);
    border-top: 1px solid #eaedf1;
    border-left: 1px solid #eaedf1;
  }
}

/* Dropdown animation */
@-webkit-keyframes dropdown-animate {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes dropdown-animate {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Styling Cards */
.card {
  margin-bottom: 30px;
  border: none;
  border-radius: 15px; /* More rounded corners */
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);
}

.card-header,
.card-footer {
  border-color: #ebeef4;
  background-color: #fff;
  color: #798eb3;
  padding: 15px;
  border-radius: 15px 15px 0 0; /* Rounded top corners */
}

.card-title {
  padding: 20px 0 15px 0;
  font-size: 18px;
  font-weight: 500;
  color: #012970;
  letter-spacing: 1px;
  font-family: 'Roboto', sans-serif;
}

.card-title span {
  color: #899bbd;
  font-size: 14px;
  font-weight: 400;
}

.card-body {
  padding: 0 20px 20px 20px;
}

.card-img-overlay {
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 15px; /* Rounded corners for card overlay */
}

/* Form Styling */
form input, form select, form textarea {
  border-radius: 8px; /* Smoother input fields */
  padding: 10px;
  border: 1px solid #d1d3e2;
  transition: border-color 0.3s ease;
}

form input:focus, form select:focus, form textarea:focus {
  border-color: var(--primary);
  outline: none;
}

/* Form Buttons */
form .btn {
  background-color: var(--primary);
  color: white;
  padding: 10px 20px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

form .btn:hover {
  background-color: var(--second);
}

/* Navbar Styles */
.navbar {
  background-color: var(--primary);
  padding: 15px;
}

.navbar-brand {
  font-family: 'Nunito', sans-serif;
  font-size: 1.5rem;
  color: white;
}

.navbar-brand:hover {
  color: var(--second);
}

/* Sidebar Styles */
.sidebar {
  background-color: #fff;
  height: 100vh;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.sidebar a {
  padding: 10px 15px;
  display: block;
  color: #444;
  font-family: 'Nunito', sans-serif;
  font-weight: 600;
}

.sidebar a:hover {
  background-color: var(--primary);
  color: white;
}

.sidebar .active {
  background-color: var(--primary);
  color: white;
}

.sidebar .sidebar-title {
  font-size: 1.2rem;
  font-weight: bold;
  padding: 15px;
  color: var(--primary);
}

/* Footer */
.footer {
  background-color: var(--primary);
  padding: 20px;
  color: white;
  text-align: center;
  font-family: 'Nunito', sans-serif;
}
