/* Sidebar container styling */
.sidebar {
    position: fixed;
    top: 90px;
    left: 0;
    bottom: 0;
    width: 250px;
    z-index: 996;
    transition: all 0.3s ease;
    padding: 20px;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #aab7cf transparent;
    box-shadow: 0 0 20px rgba(1, 41, 112, 0.1);
    background-color: #fff;
}

/* Responsive behavior */
@media (max-width: 1199px) {
    .sidebar {
        left: -300px;
    }
}

/* Scrollbar styling */
.sidebar::-webkit-scrollbar {
    width: 5px;
    background-color: #fff;
}

.sidebar::-webkit-scrollbar-thumb {
    background-color: #aab7cf;
}

/* Adjust margin for main content and footer when sidebar is visible */
@media (min-width: 1200px) {
    #main,
    #footer {
        margin-left: 250px;
    }
}

/* Toggling sidebar for smaller screens */
@media (max-width: 1199px) {
    .toggle-sidebar .sidebar {
        left: 0;
    }
}

/* Toggling sidebar for larger screens */
@media (min-width: 1200px) {
    .toggle-sidebar #main,
    .toggle-sidebar #footer {
        margin-left: 0;
    }
    .toggle-sidebar .sidebar {
        left: -300px;
    }
}

/* Sidebar navigation styling */
.sidebar-nav {
    padding: 0;
    margin: 0;
    list-style: none;
}

.sidebar-nav li {
    margin: 0;
    padding: 0;
    list-style: none;
}

/* Sidebar navigation items */
.sidebar-nav .nav-item {
    margin-bottom: 5px;
}

.sidebar-nav .nav-link {
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: 600;
    color: #012970;
    background: #fff;
    padding: 10px 15px;
    border-radius: 4px;
    transition: background-color 0.3s, color 0.3s;
}

.sidebar-nav .nav-link i {
    font-size: 16px;
    margin-right: 10px;
    color: #899bbd;
}

.sidebar-nav .nav-link.collapsed {
    color: #012970;
    background: #fff;
}

.sidebar-nav .nav-link.collapsed i {
    color: #899bbd;
}

/* Hover effects for navigation links */
.sidebar-nav .nav-link:hover {
    color: #4154f1;
    background: #f6f9ff;
}

.sidebar-nav .nav-link:hover i {
    color: #4154f1;
}

/* Chevron icon for collapsible sections */
.sidebar-nav .nav-link .bi-chevron-down {
    margin-left: auto;
    transition: transform 0.2s ease-in-out;
}

.sidebar-nav .nav-link:not(.collapsed) .bi-chevron-down {
    transform: rotate(180deg);
}

/* Collapsible section content */
.nav-content {
    padding-left: 15px;
}

.nav-content li {
    padding: 5px 0;
}

.nav-content li .nav-link {
    font-size: 14px;
    padding: 8px 15px;
}
.sidebar-buttons {
    margin-top: 30px; /* Add some space above the buttons */
    display: flex;
    flex-direction: column;
  }
  
  /* Base button styles */
  .btn {
    padding: 10px 15px;
    font-size: 16px;
    font-weight: bold;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 10px; /* Space between buttons */
  }
  
  /* Alert P&L button styling */
  .alert-btn {
    background-color: #d9534f; /* Red for alert */
  }
  
  .alert-btn:hover {
    background-color: green; /* Darker red on hover */
  }
  
  /* Broadcast button styling */
  .broadcast-btn {
    background-color: #0056b3; /* Blue for broadcast */
  }
  
  .broadcast-btn:hover {
    background-color: green; /* Darker blue on hover */
  }
  popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
 /* Center the popup on the screen */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensures it appears on top of everything */
  }
  
  /* Popup content box centered */
  .popup-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    width: 400px;
    text-align: center;
  }
  
  .popup-content h3 {
    margin-bottom: 15px;
  }
  
  .popup-content textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 16px;
  }
  
  /* Button styles for the popup */
  .btn.send-btn {
    background-color: #28a745;
    color: white;
    padding: 10px 20px;
    margin-right: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .btn.send-btn:hover {
    background-color: #218838;
  }
  
  .btn.close-btn {
    background-color: #dc3545;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .btn.close-btn:hover {
    background-color: #c82333;
  }