.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure the popup is above other content */
  }
  
  .popup-content {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    max-width: 400px;
    width: 90%;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    position: relative;
  }
  
  .popup-content h3 {
    margin-top: 0;
  }
  
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
    color: #000; /* Color for the close icon */
  }
  
  .close:hover {
    color: red; /* Change color on hover for emphasis */
  }
  
  .popup-content p {
    margin: 10px 0;
    font-size: 16px;
  }
  
  .popup-content a {
    color: #007bff;
    text-decoration: none;
  }
  
  .popup-content a:hover {
    text-decoration: underline; /* Underline on hover for links */
  }
  