/* Styling for the modal */
.list-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .list-modal-content {
    position: relative;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-width: 800px;
    max-height: 130vh; /* Increased height to 90% of the viewport */
    overflow-y: auto; /* Enable vertical scroll if content overflows */
  }
  
  .table-container {
    overflow-x: auto; /* Horizontal scroll if table overflows */
  }
  
  /* Close button at the top right corner */
  .close {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 24px;
    cursor: pointer;
    color: #333;
  }
  
  .close:hover {
    color: red;
  }
  
  h2 {
    margin-top: 0;
    font-size: 24px;
  }
  
  /* Table styles */
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  table, th, td {
    border: 1px solid #ddd;
  }
  
  th, td {
    padding: 10px;
    text-align: left;
  }
  
  th {
    background-color: #f4f4f4;
  }
  
  tbody tr:nth-child(even) {
    background-color: #f9f9f9;
  }