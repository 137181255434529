/* Modal overlay */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent dark background */
    z-index: 1000; /* Ensures it sits above all other content */
}

/* Modal content box */
.pac-modal-content {
    background: #fff;
    padding: 30px; /* Increased padding for better spacing */
    border-radius: 10px; /* Rounded corners */
    max-width: 1200px !important; /* Force increased max width */
    max-height: 80%;
    overflow-y: auto;
    position: relative;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Shadow for depth */
    width: 95% !important; /* Force width */
}

/* Close button styles */
.close {
    cursor: pointer;
    float: right;
    font-size: 20px;
    color: #333;
    border: none;
    background: none;
    margin-top: -10px;
}

/* Table for positions data */
.positions-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

/* Table headers and cells styling */
.positions-table th,
.positions-table td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    font-size: 14px;
    color: #333;
}

/* Table header specific styles */
.positions-table th {
    background-color: #f4f4f4; /* Light gray for headers */
    font-weight: bold;
    text-transform: uppercase;
}

/* Alternating row background color */
.positions-table tr:nth-child(even) {
    background-color: #f9f9f9; /* Light gray for even rows */
}

/* Hover effect for table rows */
.positions-table tr:hover {
    background-color: #f1f1f1; /* Light gray hover effect */
}

/* Make table responsive on smaller screens */
@media (max-width: 768px) {
    .modal-content {
        max-width: 100% !important; /* Reduce width for smaller screens */
    }

    .positions-table th,
    .positions-table td {
        font-size: 12px; /* Smaller font size for small screens */
        padding: 6px; /* Reduced padding for smaller screens */
    }
}
