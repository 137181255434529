.pnl-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .pnl-modal-content {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    width: 90%; /* Increased width of modal */
    max-width: 1200px; /* Set a maximum width */
    max-height: 80%;
    overflow-y: auto; /* Allow scrolling for the content */
    position: relative; /* To position the close button */
  }
  
  .close {
    position: absolute; /* Position it in the top right corner */
    top: 10px;
    right: 20px;
    font-size: 24px;
    cursor: pointer;
  }
  
  .pnl-container {
    display: flex;
    justify-content: space-between;
  }
  
  .pnl-column {
    width: 48%; /* Two columns side by side */
    overflow-y: auto; /* Allow scrolling for each column if needed */
  }
  
  .pnl-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .pnl-table th,
  .pnl-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .total-pnl {
    margin-top: 20px;
    text-align: right; /* Align total P&L to the right */
    font-weight: 500;

  }
  