#main {
margin-top: 90px;
padding: 20px 30px;
transition: all 0.3s;
/* background-color: blueviolet; */
}

@media (max-width: 1199px) {
#main {
padding: 20px;
}
}

