/* Container for the table with fixed height and scroll */
.table-container {
  max-height: 400px; /* Adjust the height as needed */
  overflow-y: auto; /* Enable vertical scrolling */
  margin: 0 auto; /* Center the table container */
  border-radius: 0.5rem; /* Rounded corners */
  background-color: #fff; /* Background color for the container */
  padding: 1rem; /* Padding around the table */
}

/* Styling for the scroll bar */
.table-container::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.table-container::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar thumb */
  border-radius: 4px; /* Rounded corners for the scrollbar thumb */
}

.table-container::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Darker color when hovering */
}

/* Optional styling for table */
.table {
  margin: 0;
  border-collapse: collapse; /* Ensure borders collapse */
}

/* Styling for table headers */
.table thead th {
  background-color: #39a7ff; /* Header background color */
  color: #fff; /* Text color for headers */
  padding: 0.75rem; /* Padding for table headers */
}

/* Styling for table rows */
.table tbody tr:nth-child(odd) {
  background-color: #f2f2f2; /* Alternate row color */
}

.table tbody tr:nth-child(even) {
  background-color: #fff; /* Default row color */
}

.table tbody td {
  padding: 0.75rem; /* Padding for table cells */
}

/* Styling for the Update button */
.update-button {
  display: inline-block; /* Allows for margin auto to work */
  background-color: #007bff; /* Bootstrap primary color */
  color: #fff; /* Text color */
  border: none; /* Remove border */
  padding: 0.2rem 1.5rem; /* Adjust padding for button size */
  font-size: 0.875rem; /* Smaller font size */
  border-radius: 0.7rem; /* Make the button more rounded */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.2s ease-in-out, transform 0.2s ease-in-out; /* Smooth transition */
  text-align: center; /* Center text */
  margin: 0 auto; /* Center the button */
}

.update-button:hover {
  background-color: #0056b3; /* Darker blue for hover effect */
  transform: scale(1.05); /* Slightly increase size on hover */
}

.update-button:focus {
  outline: none; /* Remove focus outline */
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5); /* Add a subtle shadow for focus */
}
.update-button.completed {
  background-color: green;
  color: white;
  border: none;
  padding: 0.2rem 1rem; /* Adjust padding for button size */
  font-size: 0.875rem; /* Smaller font size */
  border-radius: 1.5rem; /* Make the button more rounded */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.2s ease-in-out, transform 0.2s ease-in-out; /* Smooth transition */
  text-align: center; /* Center text */
}

.update-button.disabled {
  background-color: #d6d6d6; /* Light gray background for disabled button */
  color: #a0a0a0; /* Gray text color for disabled button */
  cursor: not-allowed; /* Cursor style for disabled button */
  border: 1px solid #ccc; /* Border color for disabled button */
}

.update-button:disabled {
  pointer-events: none; /* Disable pointer events for disabled button */
}
.client-details {
  margin-top: 20px;
  padding: 15px;
  border: 1px solid #ddd;
  background-color: #f9f9f9;
  border-radius: 5px;
}

.client-details h3 {
  margin-bottom: 10px;
}

.client-details p {
  margin: 5px 0;
}
.rerun-button {
  display: inline-block; /* Allows for margin auto to work */
  background-color: #28a745; /* Bootstrap success color */
  color: #fff; /* Text color */
  border: none; /* Remove border */
  padding: 0.2rem 1.5rem; /* Adjust padding for button size */
  font-size: 0.875rem; /* Smaller font size */
  border-radius: 0.7rem; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.2s ease-in-out, transform 0.2s ease-in-out; /* Smooth transition */
  text-align: center; /* Center text */
  margin: 0 auto; /* Center the button */
}

.rerun-button:hover {
  background-color: #218838; /* Darker green for hover effect */
  transform: scale(1.05); /* Slightly increase size on hover */
}

.rerun-button:focus {
  outline: none; /* Remove focus outline */
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5); /* Add a subtle shadow for focus */
}
.button-group {
  display: flex; /* Use flexbox for alignment */
  gap: 10px; /* Add space between buttons */
}
