.invoices-container {
  max-width: 100%;
  background: linear-gradient(135deg, #7c5dfa, #4c3db2);
  padding: 5px;
  font-family: "Arial", sans-serif;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  height: 720px;
}

.invoice-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  border-color: #ebeef4;
  background-color: #fff;
  color: #798eb3;
  padding: 15px;
  border-radius: 15px 15px 0 0; /* Rounded top corners */
}

.invoice-header h1 {
  font-size: 24px;
  color: #333;
}

.invoice-header p {
  font-size: 14px;
  color: #666;
}

.actions {
  display: flex;
  gap: 10px;
}

.filter-btn,
.new-invoice-btn {
  background-color: #7c5dfa;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.filter-btn:hover,
.new-invoice-btn:hover {
  background-color: #5a3dcd;
}

.invoice-list-container {
  max-height: 600px;
  overflow-y: auto;
  border-radius: 8px;
  border: 1px solid #eee;
  background-color: #fff;
  border-radius: 20px;
}

.invoice-list {
  display: flex;
  flex-direction: column;
}

.invoice-item {
  display: grid;
  grid-template-columns: 1fr 2fr 2fr 1fr 1fr;
  align-items: center; /* Centers items vertically */
  text-align: center; /* Centers text horizontally */
  padding: 15px 20px;
  border-bottom: 1px solid #eee;
}

.invoice-item:last-child {
  border-bottom: none;
}

.invoice-id {
  font-weight: bold;
  color: #333;
}

.invoice-details,
.invoice-name,
.invoice-amount,
.invoice-status {
  font-size: 14px;
  color: #555;
}

.invoice-status {
  padding: 5px 10px;
  border-radius: 5px;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
}

.status-paid {
  background-color: #e4f8e4;
  color: #2ecc71;
}

.status-pending {
  background-color: #fff4e4;
  color: #f39c12;
}

.status-unpaid {
  background-color: #f8e4e4;
  color: #e74c3c;
}

.invoice-list-container::-webkit-scrollbar {
  width: 6px;
}

.invoice-list-container::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 10px;
}

.invoice-list-container::-webkit-scrollbar-thumb:hover {
  background-color: #aaa;
}

.filter-dropdown-container {
  position: relative;
  width: 130px; /* Adjust width as needed */
  margin-right: 20px; /* Space from the edge or adjacent elements */
  display: inline-block; /* Ensure it aligns properly with adjacent elements */
}
.filter-dropdown {
  font-size: 16px;
  border: none;
  background: none;
  color: #333;
  cursor: pointer;
  padding: 10px 35px 10px 15px; /* Adjust padding for the arrow and text */
  appearance: none; /* Hides the default dropdown arrow */
  outline: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23333' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 5px center; /* Position the arrow */
  background-size: 12px;
  transition: all 0.3s ease;
}

.filter-dropdown:hover {
  color: #7c5dfa;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%237c5dfa' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
}

.filter-dropdown::placeholder {
  color: #666; /* Placeholder color for dropdown text */
}

.filter-dropdown:focus {
  outline: none; /* Removes focus outline */
}

.filter-dropdown:hover {
  color: #7c5dfa; /* Change text color on hover */
}
