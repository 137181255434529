/* Modal Container */
.modal {
    display: flex; /* Flexbox for centering */
    justify-content: center; /* Horizontal centering */
    align-items: center; /* Vertical centering */
    position: fixed; /* Fixed position relative to the screen */
    top: 0;
    left: 0;
    width: 100vw; /* Full width of the viewport */
    height: 100vh; /* Full height of the viewport */
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    z-index: 1000; /* Ensure it's above other elements */
  }
  
  /* Modal Content */
  .modal-content {
    background-color: #ffffff; /* White background for the modal */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2); /* Subtle shadow */
    padding: 20px; /* Inner padding */
    position: relative; /* For positioning the close button */
    transition: all 0.3s ease; /* Smooth transition effect */
    max-width: 500px; /* Set a max width for the modal */
    width: 100%; /* Responsive width */
    margin: 20px; /* Slight margin for mobile */
  }
  
  /* Loading Screen Centered */
  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full viewport height */
    color: white; /* Text color */
    font-size: 20px; /* Larger font size */
  }
  
  /* Close Button */
  .close {
    position: absolute; /* Position the close button */
    top: 10px; /* Adjust position */
    right: 15px; /* Adjust position */
    cursor: pointer; /* Pointer cursor for interactivity */
    font-size: 24px; /* Larger font size */
    color: #555; /* Slightly muted color */
  }
  
  
  /* Other styling remains the same */
  
  
  h2 {
    margin-bottom: 20px; /* Space below the heading */
    font-size: 22px; /* Larger font size for the title */
    color: #333; /* Dark color for better contrast */
    text-align: center; /* Center align the title */
  }
  
  label {
    display: block; /* Block display for labels */
    margin: 10px 0 5px; /* Margin for spacing */
    font-weight: bold; /* Bold labels */
    color: #444; /* Darker color for better readability */
  }
  
  input[type="text"],
  select {
    width: 100%; /* Full width inputs */
    padding: 10px; /* Inner padding */
    margin-bottom: 15px; /* Space below inputs */
    border: 1px solid #ccc; /* Light border */
    border-radius: 4px; /* Rounded corners */
    font-size: 16px; /* Font size */
  }
  
  button {
    background-color: #007bff; /* Primary button color */
    color: #fff; /* White text */
    border: none; /* Remove border */
    border-radius: 4px; /* Rounded corners */
    padding: 8px 16px; /* Inner padding */
    cursor: pointer; /* Pointer cursor for interactivity */
    font-size: 16px; /* Font size */
    margin-top: 10px; /* Space above buttons */
    transition: background-color 0.3s; /* Smooth background transition */
  }
  
  button:hover {
    background-color: #0056b3; /* Darker on hover */
  }
  
  button:focus {
    outline: none; /* Remove outline on focus */
  }
  