/* Style for the filter bar container */

/* Be more specific by targeting the .filter-bar class */
div.filter-bar input[type="text"] {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
    width: 250px;
}

div.filter-bar select {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
    cursor: pointer;
}
/* Add !important to force the style */
.filter-bar input[type="text"] {
    padding: 10px !important;
    font-size: 16px !important;
    border: 1px solid #ccc !important;
    border-radius: 5px !important;
    outline: none !important;
    width: 250px !important;
}

.filter-bar select {
    padding: 10px !important;
    font-size: 16px !important;
    border: 1px solid #ccc !important;
    border-radius: 5px !important;
    outline: none !important;
    cursor: pointer !important;
}


.filter-bar {
    display: flex !important;
    gap: 20px !important;
    margin-bottom: 20px !important;
    align-items: center !important;
  }
  
  /* Style for the search input */
  .filter-bar input[type="text"] {
    padding: 10px !important;
    font-size: 16px !important;
    flex: 1 !important;
    border: 1px solid #ccc !important;
    border-radius: 5px !important;
    outline: none !important;
    transition: border-color 0.3s ease-in-out !important;
  }
  
  /* Focus state for the search input */
  .filter-bar input[type="text"]:focus {
    border-color: #007bff !important;
  }
  
  /* Style for the dropdown filters */
  .filter-bar select {
    padding: 10px !important;
    font-size: 16px !important;
    border: 1px solid #ccc !important;
    border-radius: 5px !important;
    outline: none !important;
    cursor: pointer !important;
  }
  
  /* General layout for filter items on smaller screens */
  @media (max-width: 768px) {
    .filter-bar {
      flex-direction: column !important;
      gap: 10px !important;
    }
  
    .filter-bar input[type="text"],
    .filter-bar select {
      width: 100% !important;
    }
  }
  