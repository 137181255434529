
.header-nav ul {
    list-style: none;
    }
    .header-nav > ul {
    margin: 0;
    padding: 0;
    }
    I
    .header-nav .nav-icon {
    font-size: 22px; color: #012970;
    margin-right: 25px;
    position: relative;
    }
    .header-nav .nav-profile {
    color: #012970;
    }
    .header-nav .nav-profile img {
    max-height: 36px;
    }
    
.header-nav .nav-profile span { 
    font-size: 14px;
    font-weight: 600;
}

I
.header-nav .badge-number { 
    position: absolute;
    inset: -2px 5px auto auto; 
    font-weight: normal;
font-size: 12px;
padding: 3px 6px;
}

.header-nav .notifications {
    inset: 8px -15px auto auto !important;
}

.header-nav .notifications .notification-item { 
    display: flex;
align-items: center;
padding: 15px 10px;
transition: 0.3s;
}

.header-nav .notifications .notification-item i { 
    margin: 0 20px 0 10px;
    font-size: 24px;
}

.header-nav .notifications .notification-item h4 {
font-size: 16px;
font-weight: 600;
margin-bottom: 5px;
}
.header-nav .notifications .notification-item p {
font-size: 13px;
margin-bottom: 3px;
color:#919191;
}
.header-nav .notifications .notification-item:hover {
background-color:#f6f9ff;
}

.header-nav .messages {
    inset: 8px -15px auto auto !important;
}

.header-nav .messages .message-item { padding: 15px 10px;
transition: 0.3s;
}
.header-nav .messages .message-item a { display: flex;
}
.header-nav .messages .message-item img { 
    margin: 0 20px 0 10px;
max-height: 40px;
}

.header-nav .messages .message-item h4 {
font-size: 16px;
font-weight: 600;
margin-bottom: 5px;
color: #444444;
}


.header-nav .messages .message-item p {
    font-size: 13px;
    margin-bottom: 3px;
    color:  #919191;
    }
  
.header-nav .messages .message-item:hover {
    background-color:
    #f6f9ff;
    }
.header-nav .profile {
        min-width: 240px;
        padding-bottom: 0;
        top: 8px !important;
    }
.header-nav .profile .dropdown-header h6 {
    font-size: 18px;
    margin-bottom: 0;
    font-weight: 600;
    color: #444444;
    }
    
.header-nav .profile .dropdown-header span { 
    font-size: 14px;
}
.header-nav .profile .dropdown-item {
font-size: 14px;
padding: 10px 15px;
transition: 0.3s;
}
.header-nav .profile .dropdown-item i {
margin-right: 10px;
font-size: 18px;
line-height: 0;
}
.header-nav .profile .dropdown-item:hover {
background-color:#f6f9ff;
}
