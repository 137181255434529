/* Full-screen loading container */
.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff; /* White background */
  z-index: 9999;
  flex-direction: column;
}

/* Loading text styling */
.loading-container p {
  font-size: 18px;
  margin-top: 20px;
  color: #333;
}
