/* Modal Overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

/* Modal Content */
.zero-modal-content {
    background: white;
    padding: 30px;
    border-radius: 8px;
    width: 90% !important; /* Force increased max width */
    max-width: 500px!important; /* Force increased max width */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Button Container */
.btn-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

/* Button Styles */
.btn-package {
    background-color: #4CAF50;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.btn-package:hover {
    background-color: #45a049;
}

.btn-close {
    margin-top: 15px;
    background-color: #f44336;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.btn-close:hover {
    background-color: #e53935;
}
