.btn-action {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin: 2px 1px;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .btn-action:hover {
    background-color: #45a049;
  }
  
  /* Status button styling */
  .btn-status {
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    color: white;
    font-size: 14px;
    cursor: pointer;
  }
  
  /* Green button for active status */
  .btn-active {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin: 2px 1px;
    cursor: pointer;
    border-radius: 4px;
  }
  
  /* Red button for inactive status */
  .btn-inactive {
    background-color: red;
    color: white;
    border: none;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin: 2px 1px;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .btn-status:hover {
    opacity: 0.8;
  }
  .modal {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }
  
  .modal-content {
    background-color: white;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    
  }

  .popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure the popup is above other content */
  }
  
  .popup-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative; /* For positioning the close button */
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    color: #333;
  }
  
  .copy-button {
    background: none;
    border: none;
    cursor: pointer;
    margin-left: 5px; /* Space between the text and icon */
  }
  
  .copy-button i {
    font-size: 16px; /* Adjust icon size if needed */
    color: #007bff; /* Bootstrap primary color */
  }
  
  
  
  
  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  
  .packages-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .packages-table th,
  .packages-table td {
    border: 1px solid #ddd;
    padding: 4px; /* Reduced padding */
    font-size: 15px; /* Optional: Adjust font size */
  }
  
  .packages-table th {
    background-color: #f2f2f2;
    text-align: left;
    background-color: #4CAF50;
  }
  /* usertable.css */

.btn-status {
    padding: 5px 10px;
    border: none;
    color: white;
    cursor: pointer;
  }
  
  .btn-active {
    background-color: green;
  }
  
  .btn-inactive {
    background-color: red;
  }
  /* usertable.css */

.modal-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .modal-actions button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
  }
  
  /* Style for Reset to Zero button */
  .modal-actions button:first-child {
    background-color: #ff4d4d; /* Red background */
    color: white;
  }
  
  /* Style for Save Changes / Edit button */
  .modal-actions button:last-child {
    background-color: #4CAF50; /* Green background */
    color: white;
  }
  
  .modal-actions button:hover {
    opacity: 0.8; /* Slightly fade on hover */
  }
  
  /* Optional: Add some spacing between the buttons */
  .modal-actions button:not(:last-child) {
    margin-right: 10px;
  }
  