.dashboard {
    /* background: linear-gradient(135deg, #6e8efb, #a777e3); */
}
.dashboard .filter { 
    position: absolute; 
    right: 0px;
    top: 15px;
    }
    .dashboard .filter .icon {
    color: #aab7cf;
    padding-right: 20px;
     padding-bottom: 5px; 
     transition: 0.3s;
      font-size: 16px;
    }
    .dashboard.filter .icon:hover, 
    .dashboard.filter .icon:focus {
    color: #4154f1;
    }
    .dashboard.filter .dropdown-header {
    padding: 8px 15px;
    }
    
.dashboard.filter .dropdown-header h6 { 
    text-transform: uppercase;
     font-size: 14px;
    font-weight: 600;
letter-spacing: 1px;
color: #aab7cf;
margin-bottom: 0;
padding: 0;
}

.dashboard.filter .dropdown-item {
padding: 8px 15px;
}


.dashboard.section {
    padding: 20px;
    min-height: 100vh;
}

.dashboard .MuiCard-root {
    background: linear-gradient(135deg, #4154f1 30%, #2eca6a 90%);
    color: white;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}
