/* Card filter dropdown styling */
.filter .dropdown-menu {
    padding: 10px;
    min-width: 250px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  }
  
  .filter .dropdown-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .filter .dropdown-item label {
    font-weight: bold;
    margin-bottom: 5px;
    font-size: 14px;
  }
  
  .filter .date-picker {
    width: 100%;
    padding: 6px;
    border-radius: 4px;
    border: 1px solid #ced4da;
    font-size: 14px;
    background-color: #f8f9fa;
    transition: border-color 0.3s ease-in-out;
  }
  
  .filter .date-picker:focus {
    border-color: #007bff;
    outline: none;
  }
  
  .filter .apply-button {
    display: flex;          /* Use flexbox */
    justify-content: center; /* Center horizontally */
    align-items: center;     /* Center vertically */
    width: 100%;             /* Full width to make the text centered */
    padding: 8px 0;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    text-align: center;      /* Ensure text is centered */
    margin-top: 10px;
    transition: background-color 0.3s ease-in-out;
  }
  
  .filter .apply-button:hover {
    background-color: #0056b3;
  }
  