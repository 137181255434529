
.dashboard.info-card {
    padding-bottom: 10px;
    
}

.dashboard .info-card h6 { 
    font-size: 28px;
color: #012970; font-weight: 700;
margin: 0;
padding: 0;
}
.dashboard .card-icon {
    font-size: 32px;
line-height: 0; width: 64px;
height: 64px;
flex-shrink: 0;
flex-grow: 0;
}

/* Style adjustments for sales cards */
.dashboard .sales-card {
    padding-bottom: 5px; /* Reduce padding bottom */
}

.dashboard .sales-card h6 {
    font-size: 22px; /* Reduce font size */
}

.dashboard .sales-card .card-icon {
color: #4154f1;
background: #f6f6fe;
}


.dashboard .revenue-card .card-icon { 
    color: #2eca6a;
    background: #e0f8e9;
}

.dashboard .customers-card .card-icon {
color: #ff771d;
background: #ffecdf;
}

.capital-info {
    font-size: 0.875rem; /* Small font size */
    color: #afa7a5; /* Color */
    font-weight: bold; /* Make the text bold */
    letter-spacing: 0.5px; /* Slightly increase spacing between letters */
    margin-left: 1rem;
}
