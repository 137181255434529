/* Full-page background styling */
.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-image: url('../../Assets/logo/char2\ \(2\).jpg');
    background-size: cover;
    background-position: center;
    position: relative;
  }
  
  /* Company name styling and animation */
  .company-name {
    font-size: 48px;
    color: white;
    margin-bottom: 20px;
    animation: fadeInUp 1.5s ease-in-out forwards;
    opacity: 0;
    letter-spacing: 5px;
  }
  
  /* Company name fade-in and up animation */
  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .input-group {
    position: relative;
  }
  
  .input-group-text {
    background-color: rgba(255, 255, 255, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 5px 0 0 5px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .input-group-text i {
    font-size: 18px;
  }
  
  /* Styling the form */
  .login-form {
    background-color: rgba(255, 255, 255, 0.1);
    padding: 30px;
    border-radius: 15px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 350px;
    text-align: center;
    backdrop-filter: blur(10px);
    animation: fadeInForm 1.5s ease-in-out forwards;
    opacity: 0;
  }
  
  /* Form fade-in animation */
  @keyframes fadeInForm {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Rest of your form styles remain the same */
  .login-form h2 {
    font-size: 24px;
    color: white;
    margin-bottom: 20px;
    animation: fadeInText 2s ease-in-out forwards;
  }
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    font-size: 14px;
    color: white;
    margin-bottom: 5px;
    animation: fadeInText 2s ease-in-out forwards;
  }
  
  .form-group input {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    background-color: rgba(255, 255, 255, 0.2);
    color: white;
    font-size: 16px;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  /* Input focus animation */
  .form-group input:focus {
    outline: none;
    border-color: #ff9a9e;
    box-shadow: 0 0 10px rgba(255, 154, 158, 0.8);
  }
  .login-btn {
    width: 100%;
    padding: 10px;
    background-color: white;
    color: #3b3b98;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .login-btn:hover {
    background-color: #ff9a9e;
    color: white;
    transform: scale(1.05);
  }
  
  /* Error message styling */
  .error {
    color: red;
    margin-bottom: 10px;
    font-size: 14px;
  }
  