/* Container for the cards */
.summary-cards-container {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  margin: 0 auto;
  font-family: "Arial", sans-serif;
}

/* Styling for individual cards */
.invoice-card {
  background: linear-gradient(135deg, #7c5dfa, #4c3db2);
  border-radius: 15px;
  padding: 25px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2); /* Elegant shadow */
  backdrop-filter: blur(10px); /* Glass effect */
  border: 1px solid rgba(255, 255, 255, 0.25); /* Subtle border */
  color: #fff;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin-bottom: 30px;
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);
}

/* Hover effect for the cards */
.invoice-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 12px 20px rgba(0, 0, 0, 0.3);
}

/* Header text styling */
/* .card h3 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #f0f0f0;
} */

.invoice-card h3 {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 1px;
}

/* Amount styling */
.invoice-card .amount {
  font-size: 28px;
  font-weight: 700;
  color: #fff;
  margin-top: 10px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive design for smaller screens */
@media (max-width: 768px) {
  .summary-cards-container {
    gap: 10px;
  }

  .invoice-card {
    padding: 10px;
  }

  .invoice-card h3 {
    font-size: 10px;
  }

  .invoice-card .amount {
    font-size: 15px;
  }
}
