/* MonthlyPnlModal.css */

.month-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7); /* Darker background for better contrast */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.month-modal-content {
  background: white;
  border-radius: 8px;
  padding: 20px;
  width: 90%; /* Increased width for better responsiveness */
  max-width: 900px;
  max-height: 80%;
  overflow-y: auto; /* Allows vertical scrolling */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* Soft shadow for depth */
}

.month-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd; /* Divider below header */
  padding-bottom: 10px; /* Space below header */
}

.month-close {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #333;
  transition: color 0.3s; /* Transition effect for hover */
}

.month-close:hover {
  color: #e74c3c; /* Change color on hover */
  background: none;
  
}

.summary {
  margin: 20px 0; /* Space around the summary */
  padding: 10px;
  border: 1px solid #f2f2f2; /* Light border for summary */
  border-radius: 5px;
  background-color: #f9f9f9; /* Light background for contrast */
}

.table-container {
  max-height: 400px; /* Increased height for more rows */
  overflow-y: auto;  /* Enables scrolling if content exceeds max height */
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  border: 1px solid #ddd;
  padding: 10px; /* Increased padding for better touch targets */
  text-align: left;
}

th {
  background-color: #4CAF50; /* Green background for header */
  color: white; /* White text for header */
}

tr:nth-child(even) {
  background-color: #f2f2f2; /* Zebra striping for table rows */
}

tr:hover {
  background-color: #e9ecef; /* Highlight on hover */
}
