/* Modal Overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

/* Modal Content */
.modal-content {
    background: rgb(255, 255, 255);
    padding: 30px;
    border-radius: 8px;
    width: 90%;
    max-width: 1000px;
    max-height: 90%;
    overflow-y: auto;
    position: relative;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Modal Heading */
.modal-content h2 {
    color: black !important; /* Explicitly set heading color to black */
    font-size: 1.8em;
    margin-bottom: 20px;
    font-weight: 600;
}

/* Close Button */
.modal-close {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 1.6em;
    cursor: pointer;
    color: #666;
    background: transparent;
    border: none;
    padding: 0;
}

/* Form Container */
.form-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    color: rgb(255, 252, 252); /* Ensure form text defaults to black */
}

/* Form Group */
.form-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

/* Label */
.form-group label {
    font-weight: 500;
    margin-bottom: 0;
    color: black !important;
    font-family: 'Arial', sans-serif; /* Change 'Arial' to your preferred font */
}


/* Input */
.form-group input[type="text"],
.form-group input[type="password"],
.form-group input[type="number"] {
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 0.9em;
    color: black !important;

}
.close-button {
    position: absolute;
    top: 10px; /* Adjust as needed */
    right: 10px; /* Adjust as needed */
    font-size: 24px; /* Size of the 'X' */
    cursor: pointer;
    color: black; /* Change color as needed */
}

.close-button:hover {
    color: red; /* Change color on hover */
}


/* Checkbox Input Group */
.checkbox-group {
    display: flex;
    align-items: center;
    gap: 10px;
    color: black; /* Set checkbox text color to black */
    margin-bottom: 10px;
}

/* Style for checkbox */
.checkbox-group input[type="checkbox"] {
    width: 18px;
    height: 18px;
    accent-color: #4CAF50;
    cursor: pointer;
    margin-right: 10px;
}

/* Label next to the checkbox */
.checkbox-group label {
    font-size: 1em;
    font-weight: 500;
}

/* Buttons Container */
.btn-container {
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
    gap: 15px;
}

/* Button Styles */
.btn-submit, .btn-add {
    background-color: #4CAF50;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s ease;
}

.btn-submit:hover, .btn-add:hover {
    background-color: #45a049;
}

.btn-cancel {
    background-color: #f44336;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1em;
    transition: background-color 0.3s ease;
}

.btn-cancel:hover {
    background-color: #e53935;
}
