.btn-create-user {
  background-color: #007bff;
  color: white;
  padding: 8px 16px; /* Reduced padding */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px; /* Reduced font size */
}

.btn-create-user:hover {
  background-color: #0056b3;
}

.section {
  padding: 20px;
}

.row {
  display: flex;
  flex-wrap: wrap;
}

h1 {
  font-family: 'Arial', sans-serif;
  color: #333;
}

.user-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.user-table th, .user-table td {
  padding: 10px; /* Reduced padding */
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.user-table th {
  background-color: #007bff;
  color: white;
  font-weight: bold;
}

.user-table th select {
  margin-top: 5px;
  padding: 4px; /* Reduced padding */
  font-size: 14px;
}

.btn-list, .btn-status, .btn-edit, .btn-pos, .btn-order {
  padding: 6px 10px; /* Reduced button padding */
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 4px;
  font-size: 12px; /* Reduced font size */
}

.btn-list {
  background-color: #28a745;
}

.btn-status {
  background-color: #007bff;
}

.btn-edit {
  background-color: #ffc107;
}

.btn-pos {
  background-color: #28a745;
}

.btn-order {
  background-color: blue;
}

.filter-bar {
  display: flex;
  gap: 10px; /* Reduced gap */
  margin-bottom: 20px;
  align-items: center;
}

.filter-bar input[type="text"] {
  padding: 8px; /* Reduced padding */
  font-size: 14px; /* Reduced font size */
  flex: 1;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s ease-in-out;
}

.filter-bar input[type="text"]:focus {
  border-color: #007bff;
}

.filter-bar select {
  padding: 8px; /* Reduced padding */
  font-size: 14px; /* Reduced font size */
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
}

@media (max-width: 768px) {
  .filter-bar {
      flex-direction: column;
      gap: 10px;
  }

  .filter-bar input[type="text"],
  .filter-bar select {
      width: 100%;
  }
}
